<template lang="pug">
  .cl-table

    //- Таблица "Журнал изменений"
    table.cl-table__table

      //- Заголовки таблицы
      tr.cl-table__header
        th(
          v-for="(item, $index) in columns"
          :key="$index"
        )
          ChangeLogTableTh(
            :item="item"
            @sortColumnUp="sortColumnUp"
            @sortColumnDown="sortColumnDown"
            @filteredColumns="filteredColumns"
          )

      //- Контейнер для тела таблицы и прелоада
      tbody.cl-table__tbody(
        :style="{ 'height': showPreload ? `${33 * currentItemsPerPage}px` : 'auto' }"
      )

        //- Прелоад
        a-preload(
          v-show="showPreload"
          :table="true"
        )

        //- Тело таблицы
        tr.cl-table__row(
          v-for="(row, $index) in tableData"
          :key="$index"
        )
          td.cl-table__td(
            :class="{ 'cl-table__td--null': !item }"
            v-for="(item, $index) in row"
            :key="$index"
          ) {{ item || 'Нет данных' }}
    
    //- Пагинация
    .cl-table__pagination
      el-pagination(
        layout="prev, pager, next"
        :total="countItems"
        :page-size="currentItemsPerPage"
        @current-change="handleCurrentChange"
      )

    //- Переключение отображения количества рядов таблицы
    .cl-table__pages
      button.cl-table__pages-btn.btn.btn-reset(
        :class="{ '_active': currentItemsPerPage === value }"
        v-for="value in allValuePerPage"
        @click="changeItemsPerPage(value)"
      ) {{ value }}
</template>

<script>
import ChangeLogTableTh from './change-log-table-th'
import APreload from '../../../../components/a-preload/a-preload'
import columnsMixin from './columns-mixin'

export default {
  name: 'ChangeLogTable',

  components: {
    ChangeLogTableTh,
    APreload,
  },

  mixins: [columnsMixin],

  data() {
    return {
      currentPage: 1,
      currentItemsPerPage: 10,
      allValuePerPage: [10, 20, 40, 60],
      showPreload: false,
      sort: '-update_date',
      search: {
        update_date: '',
        object_name: '',
        field_name: '',
        old_value: '',
        new_value: '',
        user: ''
      }
    }
  },

  computed: {
    tableData() {
      return this.$store.getters['changeLog/tableData'];
    },
    countItems() {
      return this.$store.getters['changeLog/countItems'];
    },
    pages() {
      return Math.ceil(this.countItems / this.currentItemsPerPage)
    },
  },

  mounted() {
    this.getChangeLog()
  },

  methods: {
    async getChangeLog() {
      this.showPreload = true
      await this.$store.dispatch('changeLog/getChangeLog', { 
        p: this.currentPage,
        page_size: this.currentItemsPerPage,
        o: this.sort,
        update_date: this.search.update_date,
        object_name: this.search.object_name,
        field_name: this.search.field_name,
        old_value: this.search.old_value,
        new_value: this.search.new_value,
        user: this.search.user
      })
      this.showPreload = false
    },
    sortColumnUp(prop) {
      this.sort = prop
      this.getChangeLog()
    },
    sortColumnDown(prop) {
      this.sort = '-' + prop
      this.getChangeLog()
    },
    filteredColumns(prop) {
      this.search[prop.prop] = prop.searchText
      this.getChangeLog()
    },
    changeItemsPerPage(value) {
      this.currentItemsPerPage = value
      this.getChangeLog()
    },
    handleCurrentChange(value) {
      this.currentPage = value
      this.getChangeLog()
    }
  }
}
</script>

