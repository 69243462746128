<template lang="pug">
  .cl-table__head-item

    //- Заголовок таблицы
    .cl-table__head-item-top
      span {{ item.label }}

      //- Кнопки сортировки
      .cl-table__head-btn-group
        button.cl-table__head-btn-up.btn.btn-reset(
          :class="{ '_active': sortUp }"
          @click="sortColumnUp"
        )
        button.cl-table__head-btn-down.btn.btn-reset(
          :class="{ '_active': sortDown }"
          @click="sortColumnDown"
        )
    
    //- Поиск
    .cl-table__head-search
      input.cl-table__head-search-input(
        type="text"
        v-model="searchText"
        @input="filteredColumns()"
        placeholder="..."
      )
      svg(width="16" height="16")
        use(xlink:href="@/assets/images/sprite/sprite.svg#icon-search")
</template>

<script>
export default {
  name: 'ChangeLogTableTh',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      searchText: '',
      sortUp: false,
      sortDown: false
    }
  },

  mounted() {
    if (this.item.prop === 'update_date') {
      this.sortDown = true;
    } 
  },

  methods: {
    sortColumnUp() {
      this.sortDown = false
      this.sortUp = true
      this.$emit('sortColumnUp', this.item.prop)
    },
    sortColumnDown() {
      this.sortUp = false
      this.sortDown = true
      this.$emit('sortColumnDown', this.item.prop)
    },
    filteredColumns() {
      this.$emit('filteredColumns', { prop: this.item.prop, searchText: this.searchText })
    }
  }
}
</script>