export default {
  data() {
    return {
      columns: {
        update_date: {
          label: 'Дата изменения',
          prop: 'update_date',
        },
        object_name: {
          label: 'Объект изменений',
          prop: 'object_name'
        },
        field_name: {
          label: 'Параметр изменений',
          prop: 'field_name'
        },
        old_value: {
          label: 'Старое значение',
          prop: 'old_value'
        },
        new_value: {
          label: 'Новое значение',
          prop: 'new_value'
        },
        user: {
          label: 'Автор изменений',
          prop: 'user'
        }
      }
    }
  }
}